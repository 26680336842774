import { useRouter } from "next/router";
import { useMemo } from "react";

import { useTabID } from "~/components/canvas/hooks/useTabID";
import { selectAuth, selectAuthState, selectUserID } from "~/store/auth/selectors";
import { AuthStates } from "~/store/auth/types";
import { selectActiveCanvas } from "~/store/canvas/selectActiveCanvas";
import { selectActivePageID } from "~/store/canvas/selectors";
import { useAppSelector } from "~/store/hooks";
import { selectOrganization } from "~/store/organizations/selectors";
import { type LogFunc, Logger } from "~/utils/logging";

export type UseLogger = {
	debug: LogFunc;
	info: LogFunc;
	warn: LogFunc;
	error: LogFunc;
};

/**
 * Canvas Hook for use in React contexts that have access to the redux store
 *
 *
 * @param moduleName - string, name of the module to log

 * @returns UseLogger - object with debug, info, warn, and error methods
 */
export const useLogger = (moduleName: string): UseLogger => {
	const router = useRouter();
	const logger = useMemo(() => new Logger(moduleName), [moduleName]);
	const auth = useAppSelector(selectAuth);
	const tabID = useTabID();
	const authState = useAppSelector(selectAuthState);
	const organization = useAppSelector(selectOrganization(auth.org ?? ""));
	const canvasID = useAppSelector(selectActiveCanvas)?.id;
	const userID = useAppSelector(selectUserID);
	const activePageID = useAppSelector(selectActivePageID);

	if (authState === AuthStates.LoggedIn && !auth.org) {
		logger.error("No org found in profile", { auth });
	}

	const defaultContent = useMemo(
		() => ({
			userID,
			tabID,
			orgID: auth.org || "unknown",
			orgName: organization?.name || "unknown",
			canvasID: canvasID ?? "undefined",
			pageID: activePageID ?? "undefined",
			url: router.asPath,
			source: "useLogger",
			moduleName,
		}),
		[
			userID,
			auth.org,
			organization?.name,
			canvasID,
			activePageID,
			router.asPath,
			moduleName,
			tabID,
		]
	);

	const value = useMemo(
		(): UseLogger => ({
			debug: (message, content) => logger.debug(message, { ...defaultContent, ...content }),
			info: (message, content) => logger.info(message, { ...defaultContent, ...content }),
			warn: (message, content) => logger.warn(message, { ...defaultContent, ...content }),
			error: (message, content) => logger.error(message, { ...defaultContent, ...content }),
		}),
		[defaultContent, logger]
	);

	return value;
};
