import { nanoid } from "nanoid";

import { SESSION_STORAGE_KEYS } from "~/utils/storage";

export const useTabID = () => {
	let tabID = sessionStorage.getItem(SESSION_STORAGE_KEYS.TAB_ID) || "";

	if (!tabID) {
		tabID = nanoid(64);
		sessionStorage.setItem(SESSION_STORAGE_KEYS.TAB_ID, tabID);
	}

	return { tabID };
};
